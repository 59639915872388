'use client';

import type { Database, TablesUpdate } from '@/lib/supabase/database.types';
import { captureException } from '@sentry/nextjs';
import { createBrowserClient } from '@supabase/ssr';
import { usePostHog } from 'posthog-js/react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useDebounceCallback } from 'usehooks-ts';
import { User } from '../types';
export function createBrowserSupabase() {
  return createBrowserClient<Database>(process.env.NEXT_PUBLIC_SUPABASE_URL!, process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!);
}
const getBrowserUserQuery = `
      *,
      default_llm:llms(
        *,
        pricing:llm_pricings(*)
      ),
      user_billing_accounts(
        *,
        billing_account:billing_accounts(*)
      )
      `;
export const getBrowserUser = async () => {
  const supabase = createBrowserSupabase();
  const {
    data: {
      user: authUser
    }
  } = await supabase.auth.getUser();
  if (!authUser) {
    return null;
  }
  const {
    data: user,
    error: profileError
  } = await supabase.from('users').select(getBrowserUserQuery).eq('id', authUser?.id).single();
  if (profileError) {
    throw profileError;
  }
  return {
    ...authUser,
    ...user
  };
};
export type UserHookState = {
  data: User | null;
  isFetching: boolean;
  refetch: () => void;
  update: (newUser: TablesUpdate<'users'>) => Promise<User | void>;
};
const UserContext = createContext<UserHookState | null>(null);
export function UserProvider({
  children,
  user
}: {
  children: React.ReactNode;
  user: User | null;
}) {
  const [data, setData] = useState<User | null>(user);
  const [isFetching, setIsFetching] = useState(true);
  const posthog = usePostHog();
  useEffect(() => {
    if (data?.id) {
      posthog.identify(data.id, {
        email: data.email,
        username: data.username
      });
    }
  }, [data?.id, data?.email, data?.username, posthog]);
  const _refetch = useCallback(() => {
    getBrowserUser().then(user => {
      setData(user);
    }).catch(error => {
      console.error(error);
      setData(null);
    }).finally(() => {
      setIsFetching(false);
    });
  }, []);
  const refetch = useDebounceCallback(_refetch, 50);
  const update = useCallback(async (newUser: TablesUpdate<'users'>) => {
    if (!data) return;
    const res = await createBrowserSupabase().from('users').update(newUser).eq('id', data.id).is('user_billing_accounts.is_active', true).select(getBrowserUserQuery).single();
    if (res.error) {
      toast.error("Failed to update your user's data");
      captureException(res.error);
    } else {
      setData(p => ({
        ...p!,
        ...res.data
      }));
      return {
        ...data,
        ...res.data
      };
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [JSON.stringify(data)]);
  useEffect(() => {
    refetch();
  }, [refetch]);
  return <UserContext.Provider value={{
    data,
    isFetching,
    refetch,
    update
  }} data-sentry-element="unknown" data-sentry-component="UserProvider" data-sentry-source-file="browser.tsx">
      {children}
    </UserContext.Provider>;
}
export function useUser(): UserHookState {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}